import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import {
  PendingAssetsFindOptions,
  RequestImprovedDataOptions,
} from '../models/asset-management'
import { PendingFleetAsset } from '@fleet-customer/shared/types'

@Injectable({ providedIn: 'root' })
export class PendingFleetVehicleApi {
  constructor(private http: HttpClient) {}

  find(findOptions: PendingAssetsFindOptions) {
    return this.http.get<{ count: number; results: PendingFleetAsset[] }>(
      `/PendingFleetVehicle/find`,
      {
        params: setParams(new HttpParams(), findOptions),
      }
    )
  }

  convert(pendingFleetVehicleId) {
    return this.http.put(
      `/PendingFleetVehicle/${pendingFleetVehicleId}/FleetVehicle/convert`,
      {}
    )
  }

  requestImprovedData(pendingFleetVehicleId, body: RequestImprovedDataOptions) {
    return this.http.put(
      `/PendingFleetVehicle/${pendingFleetVehicleId}/requestImprovedData`,
      body
    )
  }
}

function setParams(
  params: HttpParams,
  findOptions: PendingAssetsFindOptions
): HttpParams {
  Object.keys(findOptions).forEach((key) => {
    const value = findOptions[key]
    if (value !== null && value !== 'ALL') {
      if (key === 'sort') {
        params = params.append(key, JSON.stringify(value))
      } else {
        params = params.append(key, value)
      }
    }
  })

  return params
}
